const baseUrl = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
export default {
    appurl: baseUrl,
    base_folder: +window.location.pathname,
//Local
//	api_url: 'http://127.0.0.1:8000/api/v1/',
//    dashbaord_url:'http://localhost:8080/'

//Production
    api_url: 'https://api.softpital.in/api/v1/',
    dashbaord_url:'https://dashboard.softpital.in/'
}